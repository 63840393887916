import { QueryClient } from '@tanstack/react-query'
import { supabase } from '~/utils/supabaseClient'

export type Device = {
  device_id: string
  device_name: string
  device_type: string
  device_metadata: Record<string, any>
  created_at: string
  device_auth_id: string
  device_config: {
    job_pausing: boolean
    enforce_station_picking: boolean
  }
}

export const deviceDetailsQuery = (id: string) => ({
  queryKey: ['device', 'details', id],
  queryFn: async () => {
    const { data, error } = await supabase
      .from('devices')
      .select()
      .eq('device_id', id)
      .single<Device>()

    if (!data || error) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }

    if (data.device_metadata.current_job_card?.job_card_id) {
      const jobCard = await supabase
        .from('job_cards')
        .select(
          `
          job_card_operation_name,
        `,
        )
        .eq('job_card_id', data.device_metadata.current_job_card.job_card_id)
        .single()
      data!.device_metadata!.current_job_card.job_card_operation_name =
        jobCard.data?.job_card_operation_name
    } else if (data.device_metadata?.current_job_card_ids) {
      const jobCards = await supabase
        .from('job_cards')
        .select(
          `
          job_card_operation_name,
                    job_card_id
        `,
        )
        .in('job_card_id', data.device_metadata?.current_job_card_ids)
      data!.device_metadata!.current_job_cards = jobCards.data
    }
    console.log(data)
    return data as Device
  },
})

export const clearDeviceStation = async (deviceId: string) => {
  const { data } = await supabase
    .from('devices')
    .select('device_metadata')
    .eq('device_id', deviceId)
    .single<{
      device_metadata: any
    }>()
  const { error } = await supabase
    .from('devices')
    .update({
      device_metadata: {
        ...data?.device_metadata,
        current_station: null,
      },
    })
    .eq('device_id', deviceId)
  if (error) {
    throw error
  }
  return
}

export const clearDeviceJobCard = async (deviceId: string) => {
  const { data } = await supabase
    .from('devices')
    .select('device_metadata')
    .eq('device_id', deviceId)
    .single<{
      device_metadata: any
    }>()
  const { error } = await supabase
    .from('devices')
    .update({
      device_metadata: {
        ...data?.device_metadata,
        current_job_card: null,
      },
    })
    .eq('device_id', deviceId)

  if (error) {
    throw error
  }
  return
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = deviceDetailsQuery(params.deviceId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
