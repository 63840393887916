import { Icon } from '@chakra-ui/react'
import { BiCheckboxMinus, BiPlayCircle } from 'react-icons/bi'
import { BsFillStopwatchFill } from 'react-icons/bs'
import { CiNoWaitingSign } from 'react-icons/ci'
import { FaFileSignature, FaHome } from 'react-icons/fa'
import { GoNote } from 'react-icons/go'
import { GrUserWorker } from 'react-icons/gr'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import {
  IoCheckbox,
  IoChevronDown,
  IoPauseCircle,
  IoPencil,
  IoPersonAdd,
  IoPlay,
  IoTodayOutline,
} from 'react-icons/io5'
import { MdFreeBreakfast } from 'react-icons/md'
import { RiPauseCircleFill, RiTicketFill, RiTodoFill } from 'react-icons/ri'

type JobState =
  | 'canceled'
  | 'clocked_out'
  | 'complete'
  | 'downtime_report_submitted'
  | 'downtime'
  | 'headcount_changed'
  | 'in_process_check_changed'
  | 'in_progress'
  | 'job_signed_off'
  | 'job_step_submitted'
  | 'job_take_over'
  | 'job_workers_changed'
  | 'on_break'
  | 'overrun_report_submitted'
  | 'overrun'
  | 'paused'
  | 'restarted'
  | 'scheduled'
  | 'started'
  | 'submit_downtime_report_causes'
  | 'batch_process_canceled'
  | 'batch_process_in_progress'
  | 'batch_process_started'

export const scheduled_states: Array<JobState> = ['scheduled', 'canceled', 'batch_process_canceled']
export const running_states: Array<JobState> = [
  'started',
  'restarted',
  'in_progress',
  'downtime',
  'batch_process_started',
  'batch_process_in_progress',
]
export const running_state_no_downtime: Array<JobState> = [
  'started',
  'restarted',
  'in_progress',
  'batch_process_started',
  'batch_process_in_progress',
]
export const stopped_states: Array<JobState> = ['clocked_out', 'on_break', 'paused']
export const in_process_states: Array<JobState> = [
  'started',
  'restarted',
  'in_progress',
  'clocked_out',
  'on_break',
  'downtime',
  'paused',
  'batch_process_started',
  'batch_process_in_progress',
]
export const complete_states: Array<JobState> = ['complete', 'job_signed_off']

export function mapJobStateTitle(t: any, state: JobState | null): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'batch_process_canceled':
      return t('status.scheduled', { ns: 'jobs' })
    case 'on_break':
      return t('status.on_break', { ns: 'jobs' })
    case 'started':
    case 'batch_process_started':
      return t('status.started', { ns: 'jobs' })
    case 'restarted':
      return t('status.restarted', { ns: 'jobs' })
    case 'in_progress':
    case 'batch_process_in_progress':
      return t('status.in_progress', { ns: 'jobs' })
    case 'downtime':
      return t('status.downtime', { ns: 'jobs' })
    case 'downtime_report_submitted':
      return t('status.downtime_report_submitted', { ns: 'jobs' })
    case 'submit_downtime_report_causes':
      return t('status.submit_downtime_report_causes', { ns: 'jobs' })
    case 'overrun_report_submitted':
      return t('status.overrun_report_submitted', { ns: 'jobs' })
    case 'clocked_out':
      return t('status.clocked_out', { ns: 'jobs' })
    case 'complete':
      return t('status.completed', { ns: 'jobs' })
    case 'paused':
      return t('status.paused', { ns: 'jobs' })
    case 'headcount_changed':
      return t('status.headcount_changed', { ns: 'jobs' })
    case 'job_workers_changed':
      return t('status.job_workers_changed', { ns: 'jobs' })
    case 'job_step_submitted':
      return t('status.job_step_submitted', { ns: 'jobs' })
    case 'in_process_check_changed':
      return t('status.in_process_check_changed', { ns: 'jobs' })
    case 'job_signed_off':
      return t('status.job_signed_off', { ns: 'jobs' })
    case 'job_take_over':
      return t('status.job_take_over', { ns: 'jobs' })
  }
}

export function mapJobStateImage(state: JobState | null, iconSize: string = '1.75em'): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'batch_process_canceled':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={CiNoWaitingSign}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'on_break':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={MdFreeBreakfast}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'started':
    case 'restarted':
    case 'in_progress':
    case 'batch_process_started':
    case 'batch_process_in_progress':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={BiPlayCircle}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'downtime':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={BsFillStopwatchFill}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'downtime_report_submitted':
    case 'overrun_report_submitted':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={GoNote}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'submit_downtime_report_causes':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={RiTicketFill}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'clocked_out':
      return <Icon backgroundColor={'#f8fafc'} height={'2em'} as={FaHome} size={iconSize} />
    case 'complete':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={IoMdCheckmarkCircle}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'paused':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={RiPauseCircleFill}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'headcount_changed':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={GrUserWorker}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'in_process_check_changed':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={RiTodoFill}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
    case 'job_signed_off':
      return (
        <Icon
          backgroundColor={'#f8fafc'}
          height={'2em'}
          as={FaFileSignature}
          size={iconSize}
          color={mapJobStateWithColor(state, true)}
        />
      )
  }
}

export function mapJobStateImageAlternative(
  state: JobState | null,
  iconSize: string = '2em',
): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'batch_process_canceled':
      return (
        <Icon as={IoTodayOutline} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
      )
    case 'started':
    case 'restarted':
    case 'in_progress':
    case 'batch_process_started':
    case 'batch_process_in_progress':
      return <Icon as={IoPlay} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
    case 'downtime':
      return (
        <Icon as={IoChevronDown} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
      )
    case 'downtime_report_submitted':
    case 'overrun_report_submitted':
      return (
        <Icon as={IoChevronDown} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
      )
    case 'submit_downtime_report_causes':
      return (
        <Icon as={IoChevronDown} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
      )
    case 'complete':
      return <Icon as={IoCheckbox} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
    case 'paused':
    case 'clocked_out':
    case 'on_break':
      return (
        <Icon as={IoPauseCircle} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
      )
    case 'headcount_changed':
      return <Icon as={IoPersonAdd} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
    case 'in_process_check_changed':
      return <Icon as={IoPencil} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
    case 'job_signed_off':
      return <Icon as={IoCheckbox} fontSize={iconSize} color={mapJobStateWithColor(state, true)} />
  }
}

export function mapJobStateWithTextColor(state: JobState | null): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'batch_process_canceled':
    case 'on_break':
      return 'black'
    default:
      return 'white'
  }
}

export function mapJobStateWithColor(
  state: JobState | null,
  withPalette: boolean = false,
  palletteColor: string = '400',
): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'clocked_out':
    case 'on_break':
    case 'batch_process_canceled':
      return `yellow${withPalette ? `.${palletteColor}` : ''}`
    case 'started':
    case 'restarted':
    case 'in_progress':
    case 'batch_process_started':
    case 'batch_process_in_progress':
      return `green${withPalette ? `.${palletteColor}` : ''}`
    case 'downtime':
    case 'overrun':
    case 'downtime_report_submitted':
    case 'submit_downtime_report_causes':
    case 'overrun_report_submitted':
      return `red${withPalette ? `.${palletteColor}` : ''}`
    case 'complete':
    case 'paused':
      return `blue${withPalette ? `.${palletteColor}` : ''}`
    case 'headcount_changed':
    case 'job_step_submitted':
    case 'in_process_check_changed':
    case 'job_signed_off':
      return `black${withPalette ? `.${palletteColor}` : ''}`
  }
}

export function mapJobStateBackgrounds(state: JobState | null): any | null {
  switch (state) {
    case 'scheduled':
    case 'canceled':
    case 'on_break':
    case 'batch_process_canceled':
      return `yellow.100`
    case 'started':
    case 'restarted':
    case 'in_progress':
    case 'batch_process_started':
    case 'batch_process_in_progress':
      return 'green.100'
    case 'downtime':
    case 'overrun':
    case 'downtime_report_submitted':
    case 'submit_downtime_report_causes':
    case 'overrun_report_submitted':
      return `red.400`
    case 'clocked_out':
    case 'complete':
      return `blue.100`
    case 'paused':
      return `blackAlpha.300`
    case 'headcount_changed':
    case 'job_step_submitted':
    case 'in_process_check_changed':
    case 'job_signed_off':
      return `black.400`
  }
}

export function mapJobStateWithIcon(state: JobState | null): any | null {
  switch (state) {
    case 'scheduled':
    case 'clocked_out':
    case 'on_break':
    case 'paused':
    case 'started':
    case 'restarted':
    case 'in_progress':
    case 'downtime':
    case 'downtime_report_submitted':
    case 'submit_downtime_report_causes':
    case 'overrun_report_submitted':
    case 'canceled':
    case 'job_signed_off':
    case 'batch_process_canceled':
    case 'batch_process_started':
    case 'batch_process_in_progress':
      return <ImCheckboxUnchecked />
    case 'complete':
      return <ImCheckboxChecked />
    default:
      return <BiCheckboxMinus size="1.75em" /> // Match the size of the other icons
  }
}

export const jobCanBeManualCompleted = (state: JobState): boolean =>
  state === 'scheduled' || state === 'canceled' || state === 'paused'

export default JobState
