import { QueryClient } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { z } from 'zod'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'

export type Partner = Database['public']['Tables']['partners']['Row']

export type drawing_change_control_flow = 'spreadsheet' | 'product_managed'

type PartnerSettings = {
  automatic_work_order_jobs_completion?: boolean | undefined
  drawing_change_control_flow?: drawing_change_control_flow | undefined
  work_order_levels?: string[] | undefined
}

export const partnerDetailsQuery = (id: string) => ({
  queryKey: ['partner', 'details', id],
  queryFn: async () => {
    const response = await supabase.from('partners').select().eq('partner_name', id).single()
    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as Partner
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = partnerDetailsQuery(params.partnerName)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }

export const partnerSettings = (partnerName: string) => ({
  queryKey: ['partner', 'settings', partnerName],
  queryFn: async () => {
    const { data } = await supabase
      .from('partners')
      .select('partner_settings')
      .eq('partner_name', partnerName)
      .single<{ partner_settings: PartnerSettings }>()
    return data?.partner_settings
  },
})

export const updatePartnerSettings = async ({
  partnerName,
  settings,
}: {
  partnerName: string
  settings: PartnerSettings
}) => {
  const { data: currentData, error: fetchError } = await supabase
    .from('partners')
    .select('partner_settings')
    .eq('partner_name', partnerName)
    .single()

  if (fetchError) {
    throw fetchError
  }

  const updatedSettings = {
    ...(currentData?.partner_settings ?? {}),
    ...settings,
  }

  const { data, error } = await supabase
    .from('partners')
    .update({ partner_settings: updatedSettings })
    .eq('partner_name', partnerName)
    .select('partner_settings')
    .single()

  if (error) {
    throw error
  }

  return data.partner_settings
}

export const updateWorkOrderLevels = async ({
  partnerName,
  workOrderLevels,
}: {
  partnerName: string
  workOrderLevels: string[]
}) => {
  const session = await supabase.auth.getSession()
  const response = await fetch(
    `${import.meta.env.VITE_TRUNK_BACKEND_URL}/partners/${encodeURIComponent(partnerName)}/work-order-level-keys`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${session?.data?.session?.access_token}`,
      },
      body: JSON.stringify({ work_order_level_keys: workOrderLevels }),
    },
  )

  if (!response.ok) {
    throw new Error(`Failed to update work order levels: ${response.statusText}`)
  }

  const data = await response.json()
  return data.partner_settings
}

export type Factory = Database['public']['Tables']['factories']['Row']
export const partnerFactories = (partnerName: string) => ({
  queryKey: ['partner', 'factories', partnerName],
  queryFn: async () => {
    const { data } = await supabase
      .from('factories')
      .select()
      .eq('partner_name', partnerName)
      .order('factory_name')
    return data as Factory[]
  },
})

export const FactoryInput = (t: TFunction<[string, string, string], undefined>) =>
  z.object({
    factory_name: z
      .string()
      .min(1, { message: `${t('factory_name_is_mandatory', { ns: 'validation' })}` }),
    factory_location: z.object({
      lat: z.number().default(0),
      lng: z.number().default(0),
    }),
    factory_timezone: z.string().optional(),
  })
export type FactoryInput = z.infer<ReturnType<typeof FactoryInput>>

export const createFactory = async ({
  partner_name,
  factory,
}: {
  partner_name: string
  factory: FactoryInput
}) => {
  const { data, error } = await supabase.functions.invoke('watchtower-create-factory', {
    body: {
      partner_name,
      ...factory,
      factory_location: {
        lat: parseFloat((factory.factory_location.lat as any) ?? '0'),
        lng: parseFloat((factory.factory_location.lng as any) ?? '0'),
      },
    },
  })
  if (error) {
    throw error
  }
  return data
}

export const getPartnerData = async (partnerName: string) => {
  const session = await supabase.auth.getSession()
  const response = await fetch(
    `${import.meta.env.VITE_TRUNK_BACKEND_URL}/partners?partner_name=${encodeURIComponent(partnerName)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${session?.data?.session?.access_token}`,
      },
    },
  )

  if (!response.ok) {
    throw new Error(`Failed to fetch partner data: ${response.statusText}`)
  }

  const data = await response.json()
  return data as Partner
}

export const partnerDataQuery = (partnerName: string) => ({
  queryKey: ['partner', 'details', partnerName],
  queryFn: () => getPartnerData(partnerName),
})
