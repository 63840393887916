import { TFunction } from 'i18next'
import { JobEvent } from './api'

export function formatEventMetadata(
  t: TFunction,
  event: JobEvent,
  index: number,
  rows: JobEvent[],
): string | string[] {
  switch (event.job_event_type) {
    case 'started':
    case 'restarted':
    case 'batch_process_started':
      return event.job_event_metadata?.job_headcount
        ? [
            `${t('headcount')}: ${event.job_event_metadata?.job_headcount ?? 0}`,
            `${t('workers')}: ${event.job_event_metadata?.job_worker_names?.join(', ') ?? ''}`,
          ]
        : []
    case 'headcount_changed':
      return `${
        rows
          .slice(index + 1)
          .find(
            (row) => row.job_event_type === 'headcount_changed' || row.job_event_type === 'started',
          )?.job_event_metadata?.job_headcount ?? ''
      } ➞ ${event.job_event_metadata?.job_headcount ?? ''}`
    case 'job_workers_changed':
      return `${t('new_workers')}: ${event.job_event_metadata?.job_worker_names.join(', ') ?? ''}`
    case 'downtime_report_submitted':
      return `${t('note')}: ${event?.job_event_metadata?.note ?? '-'}`
    case 'overrun_report_submitted':
      return [
        `${t('note')}: ${
          event.job_event_metadata?.overrun_report.note &&
          event.job_event_metadata?.overrun_report.note !== ''
            ? event.job_event_metadata?.overrun_report.note
            : '-'
        }`,
        `${t('causes')}:`,
        ...formCauses(event.job_event_metadata?.overrun_report.causes ?? {}),
      ]
    case 'submit_downtime_report_causes':
      return [t('reasons'), ...formCauses(event.job_event_metadata?.causes ?? {})]
    case 'in_process_check_changed':
      return `${t('check')}: ${event.job_event_metadata?.in_process_check.name ?? ''}`
    case 'job_signed_off':
      return `${t('signer')}: ${event.job_event_metadata?.signer?.worker_first_name ?? ''} ${
        event.job_event_metadata?.signer?.worker_last_name ?? ''
      }`
    case 'job_take_over':
      return `${t('new_station')}: ${event.job_event_metadata.device.station.zone_name ?? ''}, ${event.job_event_metadata.device.station.station_name ?? ''}`
  }
  return ''
}

const formCauses = (causes: { [key: string]: string[] }) =>
  Object.entries(causes).map(([k, v]) => `${k} -> ${v?.join(', ') ?? ''}`)
