import { BillOfOperationsVersionsOperationsVersions } from '~/routes/production_planning_pages/products/ProductDetailsPage/tabs/BillOfOperationsTab/api'

export const setDataOrder = (
  data: BillOfOperationsVersionsOperationsVersions[],
): BillOfOperationsVersionsOperationsVersions[] => {
  return data?.map((operation: BillOfOperationsVersionsOperationsVersions, index: number) => ({
    ...operation,
    operation_order: index + 1,
  })) as BillOfOperationsVersionsOperationsVersions[]
}
