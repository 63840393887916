import { QueryClient } from '@tanstack/react-query'
import { UniclassCodesType } from '~/routes/production_planning_pages/products/ProductFamilies/api'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'
import {
  BillOfOperationsVersionsOperationsVersions,
  Operation,
} from './tabs/BillOfOperationsTab/api'
import { setDataOrder } from './tabs/BillOfOperationsTab/helpers'

export type ProductDetails = Database['public']['Tables']['bill_of_operations_versions']['Row'] & {
  profiles: {
    profile_first_name: string | null
    profile_last_name: string | null
  }
  uniclass_codes: UniclassCodesType
  bills_of_operations: {
    product_id: string | null
  }
}

export const productDetailsQuery = (id: string, version?: string) => ({
  queryKey: ['product', 'detail', id, version],
  queryFn: async () => {
    const request = supabase.from('bill_of_operations_versions').select(
      `
        *,
        profiles(
          profile_first_name,
          profile_last_name
        ),
        bills_of_operations(product_id),
        uniclass_codes(*)
      `,
    )
    if (version) {
      request.eq('bill_of_operations_version', version)
    } else {
      request.eq('is_current_version', true)
    }
    const response = await request
      .match({
        bill_of_operations_id: id,
      })
      .maybeSingle()

    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as ProductDetails
  },
})

interface UpdateBillOfOperationsParams {
  productDetails: ProductDetails
  billOfOperations?: (
    | BillOfOperationsVersionsOperationsVersions
    | {
        bill_of_operations_id: string
        operation_order: number
        operation_versions: Operation
      }
  )[]
}

interface UpdateBillOfOperationsBody {
  operations?: Array<{
    operation_id: string
    operation_version: number
    operation_order: number
  }>
  [key: string]: unknown
}

export const updateBillOfOperations = async ({
  productDetails,
  billOfOperations,
}: UpdateBillOfOperationsParams) => {
  const body: UpdateBillOfOperationsBody = { ...productDetails }

  if (billOfOperations) {
    const pinnedOperations = billOfOperations.map((operation) => ({
      operation_id: operation.operation_versions.operation_id,
      operation_version: operation.operation_versions.operation_version,
      operation_order: operation.operation_order ?? 0,
    }))
    body.operations = setDataOrder(pinnedOperations)
  }

  try {
    const { error } = await supabase.functions.invoke('update-bill-of-operations', {
      body,
    })

    if (error) {
      throw new Error(error.message)
    }

    return true
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
    throw new Error('An unknown error occurred')
  }
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = productDetailsQuery(params.billOfOperationsId, params.billOfOperationsVersion)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
