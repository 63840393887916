import { selector } from 'recoil'
import sessionStorage from './sessionStorage'

export const isLowfieldTimberFrameUserCheck = selector({
  key: 'IsLowfieldUser',
  get: ({ get }) => {
    const email = get(sessionStorage)?.user?.email
    return (
      (email?.endsWith('@ltf.uk.com') || /^ltf+.*@trunkdevices.co.uk$/.test(email ?? '')) ?? false
    )
  },
})
